import React, { useState } from 'react';
import apert from '../assets/apert.png'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const Login = () => {

   
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('https://server2.apertscribe.co.zw/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      console.log('Login Response:', response);
  
      if (response.ok) {
        
        navigate('/homepage');
      } else {
        // Handle authentication failure
        toast.error('Please provide correct credentials to login', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className='flex flex-row h-[100vh]'>
      <div className='md:w-1/3 w-full bg-white flex justify-center items-center border-t-8 border-t-[#BD1010]'>
        <form onSubmit={handleSubmit}>
          <div className='bg-white shadow-lg p-5 mt-[35%] border-2 border-gray-200 '>
            <input
              type='text'
              id='username'
              name='username'
              placeholder='username'
              value={formData.username}
              onChange={handleInputChange}
              className='text-center text-2xl font-bold text-black'
              autoComplete='username'
            />
          </div>
          <div className='bg-white shadow-lg p-5 mt-[20%] border-2 border-gray-200 '>
            <input
              type='password'
              id='password'
              name='password' 
              placeholder='password'
              onChange={handleInputChange}
              value={formData.password}
              className='text-center text-2xl font-bold text-black '
              autoComplete='current-password'
            />
          </div>
          <button className='bg-[#051229] px-10 py-3 font-bold rounded-sm text-white ml-[35%] mt-[20%]' type='submit'>LOGIN</button>
        </form>
      </div>
      <div
       className='w-2/3    hidden md:flex  justify-center items-center bg-center bg-no-repeat '
       style={{ backgroundImage: `url(${apert})` }}
      > 
      <h1 className='text-4xl font-bold text-red-800'>Apertscribe Admin Panel</h1>

      </div>
      <ToastContainer/>
    </div>
  )

}

export default Login
