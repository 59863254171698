import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Vieworder = () => {
  const { order_id } = useParams(); // Extract the orderId from the URL parameter
  const [orderDetails, setOrderDetails] = useState();

  // Fetch order details using the orderId
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`https://server2.apertscribe.co.zw/api/v1/orders/${order_id}`);
        if (response.ok) {
          const orderDetailsData = await response.json();

          // Check if order_items is a string and parse it if needed
          if (orderDetailsData[0]?.order_items && typeof orderDetailsData[0].order_items === 'string') {
            orderDetailsData[0].order_items = JSON.parse(orderDetailsData[0].order_items);
          }

          // Set order details in state
          setOrderDetails(orderDetailsData[0]);
        } else {
          console.error('Error fetching order details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [order_id]);

  if (!orderDetails) {
    return (
      <div>

        Loading...
      </div>
    );
  }

  return (
    <div className='py-5'>
      <Navbar />
      <h2 className='text-3xl font-bold text-[#1E1111] mt-10 mb-5'>ORDER DETAILS</h2>
      {orderDetails.order_items && orderDetails.order_items.length > 0 && (
        <div>

          <table  >
            <thead >
              <tr className='w-full flex justify-between bg-[#090D2F] py-3 px-2 font-bold text-white'>
                <th className='md:w-[10%] w-[25%]'>Image</th>
                <th className='md:w-[15%] w-[25%]'>Name</th>
                <th className='md:w-[15%] w-[25%]'>Price</th>
                <th className='md:w-[15%] w-[25%]'>Quantity to Buy</th>
                <th className='hidden md:block md:w-[45%]'>Description</th>

              </tr>
            </thead>
            <tbody>


              {orderDetails.order_items.map((item, index) => (

                <tr key={index} className='w-full items-center flex justify-between mb-1 h-[100px] mt-1 shadow-md py-2 px-2'>
                  <td className='md:w-[10%] w-[25%] '>
                    {Array.isArray(item.picture_path) && item.picture_path.length > 0 && (
                      <img
                        src={item.picture_path[0].updated || item.picture_path[0].picture_path}  // Use the 'updated' property in the first object of the array
                        alt={item.name}
                        className='w-[50px] h-[50px]'
                      />
                    )}
                  </td>
                  <td className='text-center md:w-[15%] w-[25%]' >{item.name}</td>
                  <td className='text-center md:w-[15%] w-[25%]' >{item.price}</td>
                  <td className='text-center md:w-[15%] w-[25%]' >{item.quantityToBuy}</td>
                  <td className=' md:w-[45%] relative hidden md:block'>
                    <div style={{ height: '80px', overflow: 'auto' }}>
                      {item.description}
                    </div>
                  </td>





                </tr>
              ))}
            </tbody>
          </table>


          <h2 className='text-3xl font-bold text-[#1E1111] mt-10'>PERSONAL INFORMATION</h2>

          <div className=" flex flex-col  mt-5    mb-10 justify-between  w-full md:w-[70%] p-5">

            <div className='flex h-[50px] mb-1 justify-between   '>
              <h1>Order ID</h1>
              <p>{orderDetails.order_id}</p>
            </div>
            <div className='flex h-[50px] mb-1 justify-between  '>
              <h1>Customer Name</h1>
              <p>{orderDetails.customer_name}</p>
            </div>
            <div className='flex h-[100px] md:h-[50px] mb-1 justify-between  '>
              <h1>Address</h1>
              <p className='text-right'>{orderDetails.address}</p>
            </div>
            <div className='flex h-[50px] mb-1 justify-between  '>
              <h1>Email</h1>
              <p>{orderDetails.email}</p>
            </div>
            <div className='flex h-[50px] mb-1 justify-between  '>
              <h1>transaction date</h1>
              <p>{orderDetails.transaction_date}</p>
            </div>
            <div className='flex h-[50px] mb-1 justify-between  '>
              <h1>Delivery date </h1>
              <p>{orderDetails.Delivery_date}</p>
            </div>
            <div className='flex h-[50px] mb-1 justify-between  '>
              <h1>Phone Number</h1>
              <p>{orderDetails.phone_number}</p>
            </div>


          </div>

        </div>
      )}
    </div>
  );
};

export default Vieworder;
